<template>
  <div class="casting-style">
    <titles :title="type == 1 ? $t('text.t444') : $t('text.t445')" />
    <div class="bigbox">
      <div class="bigbox-t1 bigbox-mb10">
        {{ type == 1 ? $t('text.t446') : $t('text.t447') }}
      </div>
      <div class="bb-inp bb-mb20">
        <el-input
          v-model="names"
          :placeholder="type == 1 ? $t('text.t448') : $t('text.t449')"
        ></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t450')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input
          v-model="phone"
          type="number"
          :placeholder="$t('text.t451')"
        ></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">
        {{ type == 1 ? $t('text.t452') : $t('text.t453') }}
      </div>
      <div class="bb-inp bb-mb20">
        <el-input
          type="textarea"
          v-model="describe"
          :placeholder="type == 1 ? $t('text.t454') : $t('text.t455')"
        ></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t456')}}</div>
      <div class="bb-mb20">
        <van-uploader
          :max-count="6"
          :after-read="afterRead"
          v-model="fileList"
          multiple
        />
      </div>
      <template v-if="type == 2">
        <div class="bigbox-t1 bigbox-mb10">{{$t('text.t457')}}</div>
        <div class="bb-mb20">
          <van-uploader
            :max-count="1"
            :after-read="afterRead1"
            v-model="fileList1"
            multiple
          />
        </div>
      </template>
      <div class="bb-btn">
        <van-button
          @click="submit"
          :loading="isloading"
          block
          color="#323232"
          :loading-text="$t('text.t67')"
          >{{$t('text.t458')}}</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components: {
    titles,
  },
  data() {
    return {
      fileList: [],
      upimg: "",
      fileList1: [],
      upimg: "",
      names: "",
      phone: "",
      describe: "",
      isloading: false,
      tmsg: "",
      type: "",
    };
  },
  computed: {
    ...mapState(["language", "chainId", "config"]),
  },
  created() {
    this.type = this.$route.query.type;
  },
  mounted() {},
  methods: {
    async submit() {
      if (!this.names) {
        this.$toast(this.$t('text.t459'));
        return;
      }
      if (!this.phone) {
        this.$toast(this.$t('text.t460'));
        return;
      }
      if (!this.describe) {
        this.$toast(this.$t('text.t461'));
        return;
      }
      if (!this.fileList.length) {
        this.$toast(this.$t('text.t462'));
        return;
      }
      if (this.type == 2 && !this.fileList.length) {
        this.$toast(this.$t('text.t463'));
        return;
      }
      this.isloading = true;
      this.$http
        .post("/casting/creatorOrder", {
          flag: this.type == 1 ? 0 : 1,
          type: this.type == 1 ? 0 : 1,
          title: this.names,
          phone: this.phone,
          intro: this.describe,
          images: this.fileList.map((ele) => ele.surl).join(),
          image: this.fileList1.map((ele) => ele.surl).join(),
        })
        .then((res) => {
          this.$toast(res.msg);
          setTimeout(() => {
            this.isloading = false;
            this.$router.go(-1);
          }, 1500);
        })
        .catch((err) => {
          this.isloading = false;
        });
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = this.$t("text.t74");
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http
        .upload("/common/upload", ff)
        .then((res) => {
          this.fileList.splice(this.fileList.length - 1, 1, {
            url: res.data.fullurl,
            surl: res.data.url,
          });
          console.log(this.fileList);
          setTimeout(() => {
            file.status = "done";
            file.message = this.$t("text.t75");
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            file.status = "failed";
            file.message = this.$t("text.t76");
          }, 1000);
        });
    },
    afterRead1(file) {
      file.status = "uploading";
      file.message = this.$t("text.t74");
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http
        .upload("/common/upload", ff)
        .then((res) => {
          this.fileList1.splice(this.fileList1.length - 1, 1, {
            url: res.data.fullurl,
            surl: res.data.url,
          });
          console.log(this.fileList1);
          setTimeout(() => {
            file.status = "done";
            file.message = this.$t("text.t75");
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            file.status = "failed";
            file.message = this.$t("text.t76");
          }, 1000);
        });
    },
  },
};
</script>

<style lang="less">
.casting-style {
  .bigbox {
    width: 321px;
    padding: 41px 0;
    margin: auto;
    .box1 {
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t1 {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t2 {
      font-size: 12px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-mb10 {
      margin-bottom: 10px;
    }
    .bb-mb20 {
      margin-bottom: 20px;
    }
    .bb-inp {
      width: 100%;
      .el-select {
        display: block;
      }
    }
    .bb-btn {
      width: 300px;
      margin: auto;
      padding-top: 28px;
      .van-button {
        border-radius: 5px;
      }
    }
    .attritem {
      margin-bottom: 15px;
      .left {
        flex: 1;
        .line1 {
          width: 49%;
          .l1title {
            font-size: 12px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 10px;
          }
          .l1inp {
            width: 100%;
            .sufbox {
              height: 100%;
              padding-right: 8px;
            }
            .suf {
              width: 15px;
              height: 15px;
              background: #ffffff;
              border: 1px solid #323232;
              border-radius: 8px;
              font-size: 16px;
              font-weight: bold;
              color: #323232;
            }
          }
        }
      }
      .right {
        width: 30px;
        height: 30px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        background: #ffffff;
        border: 1px solid #323232;
        border-radius: 5px;
        margin-left: 8px;
        box-sizing: border-box;
      }
    }
    .attritem:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>